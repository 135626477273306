import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Modal from '@/components/Modal';
import config from '@/components/LegacyModal/config';
import Alert from '@/components/Alert';
import Icon from '@/components/Icon';
import FormField from '@/components/FormField';
import { RadioButton, TextArea } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import Button from '@/components/Button';
import Form from '@/components/Form';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import storage from '@/lib/storage';
import { redirect } from '@/browser';
import { ErrorMessage, useFormikContext } from 'formik';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal', 'activity']).isRequired,
    id: PropTypes.number
  }),
  feature: PropTypes.oneOf(Object.keys(config))
};

const schema = Yup.object().shape({
  answer: Yup.string()
    .when('$isOptions', {
      is: true,
      then: (fieldSchema) => fieldSchema.required('Por favor, selecione uma alternativa.')
    }),
  feedback: Yup.string().required('Resposta obrigatória.')
});

const defaultValues = {
  feedback: ''
};

function OptionsForm({ formConfig }) {
  const { values } = useFormikContext();
  const options = formConfig.options;
  const selectedOption = options[Number(values.answer)];

  return (
    <>
      {options.map(({ label }, index) => (
        <FormField
          as={RadioButton}
          id={`options_${index}`}
          key={`options_${index}`}
          radioValue={index.toString()}
          name='answer'
          label={<div className='mt-1 ms-2'>{label}</div>}
        />
      ))}

      <ErrorMessage name='answer'>
        {(message) => <div className='text-danger text-feedback'>{message}</div>}
      </ErrorMessage>

      {selectedOption && (
        <FormField
          as={TextArea}
          label={selectedOption.optionDescription.question}
          name='feedback'
          placeholder='Deixe seu feedback...'
        />
      )}
    </>
  );
}

function TextForm({ formConfig }) {
  return (
    <>
      <div className='my-3 fw-bold'>{formConfig.descriptionTitle}</div>
      <FormField
        as={TextArea}
        name='feedback'
        placeholder='Deixe seu feedback...'
      />
    </>
  );
}

function LegacyModal({ show, onHide, entity, feature }) {
  const { user } = useAuth();
  const tracker = useTracking();

  const modalConfig = useMemo(() => {
    const featureConfig = config[feature];
    return featureConfig[entity?.type] || featureConfig.base;
  }, [feature, entity?.type]);

  const isOptions = modalConfig.form.type === 'options';

  const handleFeedbackSubmit = (formValues) => {
    const screen = modalConfig.screen;
    const params = {
      user,
      screen,
      feedback: formValues.feedback,
      entity: entity?.type
    };
    if (isOptions) {
      params.answer = modalConfig.form.options[Number(formValues.answer)].label;
    }

    tracker.trackBackToLegacyConfirmed(params);
    storage.set(`lastFeedbackDate_${screen}`, new Date().toISOString());

    const url = modalConfig.buildUrl(entity);
    redirect(url);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      bodyClassName='p-8'
      size='lg'
      backdrop='static'
    >
      <h3 className='mb-4'>
        Por que você está voltando para a versão antiga?
      </h3>

      {modalConfig.showDeprecationAlert && (
        <Alert variant='light' className='text-dark-gray mb-4'>
          <span className='text-base'>
          A versão antiga será descontinuada no futuro e substituída pelo novo design.
          </span>
        </Alert>
      )}

      {Boolean(modalConfig.title) && <div className='mb-3'>{modalConfig.title}</div>}
      {Boolean(modalConfig.items) && modalConfig.items.map((item, index) => (
        <div key={index} className='d-flex align-items-center mb-2'>
          <Icon name='alert' className='text-danger me-2' />
          <span className='fw-bold'>{item}</span>
        </div>
      ))}

      <Form
        name='feedbackForm'
        defaultValues={defaultValues}
        onSubmit={handleFeedbackSubmit}
        validationSchema={schema}
        validationContext={{ isOptions }}
        validateOnMount
        className='pt-2'
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <>
            {isOptions
              ? <OptionsForm formConfig={modalConfig.form} />
              : <TextForm formConfig={modalConfig.form} />
            }

            <div className='d-flex mt-3 justify-content-end'>
              <Button
                className='me-3'
                variant='light'
                onClick={onHide}
              >
                Cancelar
              </Button>

              <LoadingButton
                variant='danger'
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loadingText='Carregando...'
                disabled={!isValid}
              >
                Voltar para a versão antiga
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

LegacyModal.propTypes = propTypes;

export default LegacyModal;

import React, { useState } from 'react';
import classnames from 'classnames';
import compact from 'lodash/compact';
import { createUppy, useUppy } from '@/lib/uppy';
import { downloadBlob, PDF_EXTENSION, PDF_TYPE } from '@/utils';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Dropdown from '@/components/Dropdown';
import CreateEmailModal from '@/components/Email/CreateEmailModal';

function ProposalFileDropdown({ url, fileName, entityId, entityType, relatedEntity }) {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareProposalEmail, setShareProposalEmail] = useState(null);

  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: [PDF_EXTENSION],
      maxFileSizeMega: 10,
      maxNumberOfFiles: 1
    });

    return newUppy;
  });

  const handleOpenEmailModal = async () => {
    const downloaded = await downloadBlob(url);
    uppy.reset();

    await uppy.addFile({
      name: fileName,
      type: PDF_TYPE,
      data: new Blob([downloaded], { type: PDF_TYPE })
    });

    const result = await uppy.upload();
    const uploadedFiles = result.successful?.map((file) => file.response.body);

    setShareProposalEmail({
      attachments: uploadedFiles,
      to: compact([relatedEntity?.contact?.email])
    });

    setShowEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    uppy.reset();
    setShowEmailModal(false);
    setShareProposalEmail(null);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        id='pdf-menu-toggle'
        variant='light'
        className={classnames(
          'd-inline-flex',
          'align-items-center',
          'cursor-pointer',
          'text-dark-gray',
          'text-small',
          'p-1'
        )}
      >
        <Icon size='sm' name='proposal' className='me-1 text-primary' />
        <Truncate as='span'>{fileName}</Truncate>
        <Icon size='sm' name='arrow-down' className='ms-2' />
      </Dropdown.Toggle>
      <Dropdown.Menu
        id='pdf-menu'
        renderOnMount
        className='width-5'
        popperConfig={{ strategy: 'fixed' }}
      >
        <Dropdown.Divider />
        <Dropdown.Item
          as='button'
          className='fw-bold text-darker-gray'
          onClick={handleOpenEmailModal}
        >
          <Icon name='email' className='me-2' />
          Enviar por e-mail
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as='button'
          onClick={ async () => {
            const blob = await downloadBlob(url);
            const blobUrl = URL.createObjectURL(new Blob([blob], { type: PDF_TYPE }));
            window.open(blobUrl, '_blank');
          }}
          className='fw-bold text-darker-gray'
        >
          <Icon name='download' className='me-2' />
          Baixar PDF
        </Dropdown.Item>
      </Dropdown.Menu>

      {
        showEmailModal
          ? (
            <CreateEmailModal
              show={showEmailModal}
              onClose={handleCloseEmailModal}
              attachments={shareProposalEmail?.attachments}
              to={shareProposalEmail?.to}
              entityId={entityId}
              entityType={entityType}
            />
          )
          : null
      }
    </Dropdown>
  );
}

export default ProposalFileDropdown;

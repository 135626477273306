import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { Container, Col, Row } from 'react-bootstrap';
import { useTracking } from '@/lib/tracking';
import { useCreateDeal, useDealsCustomFields, useDealTemplate } from '@/api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import HookFullDealForm from '@/components/HookFullDealForm';
import APIErrorMessage from '@/components/APIErrorMessage';
import Main from '@/components/Main';
import { useDocumentTitle } from '@/hooks';
import { setLegacyAlertMessage, redirect } from '@/browser';
import {
  legacyDealsUrl,
  dealPath,
  legacyDealUrl,
  legacyNewFormsOptoutUrl,
  settingsCustomFieldsPath
} from '@/routes';
import { getValidationErrors, isFeedbackRecent, isOptin } from '@/utils';
import { useAuth } from '@/lib/auth';
import OptoutButton from '@/components/OptoutButton';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LegacyModal from '@/components/LegacyModal/LegacyModal';

const PAGE_TITLE = 'Adicionar novo negócio';

function DealsNew() {
  useDocumentTitle(PAGE_TITLE);

  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const alert = useAlert();
  const tracker = useTracking();
  const createDealMutation = useCreateDeal();
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const organizationId = searchParams.get('organization_id');
  const personId = searchParams.get('person_id');

  const { data: dealTemplate, isLoading: loadingRelatedEntity } = useDealTemplate({
    params: { organization_id: organizationId, person_id: personId },
    config: {
      enabled: Boolean(organizationId || personId)
    }
  });

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user,
      page: 'Formulario',
      entity: 'Negocio',
      feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'deal' });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const { data: customFields, isSuccess: loadedCustomFields } = useDealsCustomFields();
  const eventMetadata = { source: 'newForm', entityCustomFields: customFields?.data, user };

  useEffect(() => {
    tracker.trackNewCreateDealFormViewed({ user });
  }, [user]);

  const handleSubmit = (formData, { setFieldError, setSubmitting }) => {
    createDealMutation.mutate(formData, {
      onSuccess: ({ data: { id } }) => {
        tracker.trackDealCreated({ ...eventMetadata, formData });

        setLegacyAlertMessage('Negócio inserido com sucesso!', 'success');
        alert.show(
          'Negócio inserido com sucesso!',
          { variant: 'success', timeout: 5000 }
        );

        if (user.features.blockLegacyEnabled || isOptin(user?.features?.dealModalEnabled)) {
          navigate(dealPath(id, '/deals'));
        } else {
          redirect(legacyDealUrl(id));
        }
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'deal');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='deal' action='create' />,
          { variant: 'danger' }
        );
        setSubmitting(false);
      }
    });
  };

  const handleCancel = () => redirect(legacyDealsUrl());

  const onCreateCustomer = ({ type }) => {
    const quickAddedMetadata = { ...eventMetadata, quickAdded: true };
    if (type === 'organization') {
      tracker.trackOrganizationCreated(quickAddedMetadata);
    } else {
      tracker.trackPersonCreated(quickAddedMetadata);
    }
  };

  const initialValues = useMemo(() => ({
    title: dealTemplate?.data.name || '',
    owner_user_id: user.id,
    funnel_id: user.account.defaultFunnel.id,
    privacy: { type: user.account.privacy },
    customer: getInitialCustomerData(dealTemplate?.data.organization, dealTemplate?.data.person)
  }), [user, dealTemplate]);

  if (!loadedCustomFields || loadingRelatedEntity) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center'
            >
              <div className='flex-fill'>
                <h1>{PAGE_TITLE}</h1>
              </div>

              <Button
                variant='light'
                href={settingsCustomFieldsPath('deals')}
                className='text-primary'
              >
                <Icon name='config' className='me-1' />
                Personalize este formulário
              </Button>

              <OptoutButton
                className='ms-2'
                from='newForms'
                entity={{ type: 'deal' }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <LegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        feature='newForms'
        entity={{ type: 'deal' }}
      />

      <HookFullDealForm
        initialValues={initialValues}
        customFields={customFields.data ?? []}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onCreateCustomer={onCreateCustomer}
      />
    </Main>
  );
}

function getInitialCustomerData(organization, person) {
  if (organization) {
    return {
      id: `organization-${organization.id}`,
      text: organization.name
    };
  }

  if (person) {
    return {
      id: `person-${person.id}`,
      text: person.name
    };
  }
}

export default DealsNew;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import classnames from 'classnames';
import sum from 'lodash/sum';
import Form from '@/components/Form';
import HookFormField from '@/components/HookFormField';
import ToggleFieldVisibility from '@/components/ToggleFieldVisibility';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LoadSpinner from '@/components/LoadSpinner';
import Collapse from '@/components/Collapse';
import Tooltip from '@/components/Tooltip';
import {
  DecimalInput,
  PercentualInput,
  IntegerInput,
  ProductSelect,
  RichTextInput
} from '@/components/Inputs';
import Table from '@/components/Table';

import { toCurrencyString, toPercentageString, calculatePercentage } from '@/number';
import { downloadJSONFromUrl } from '@/utils';

const propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  onSubtotalChange: PropTypes.func,
  hiddenFields: PropTypes.object,
  onChangeFieldVisibility: PropTypes.func,
  showToggleVisibility: PropTypes.bool,
  downloadMetadata: PropTypes.bool,
  feature: PropTypes.string
};

const defaultProps = {
  size: 'md',
  onSubtotalChange: () => { },
  hiddenFields: {},
  onChangeFieldVisibility: () => { },
  showToggleVisibility: false,
  downloadMetadata: false,
  feature: 'deal'
};

const DISCOUNT_ROUNDED = 10000;
const CENTS_SCALE = 4;
const FEATURES = {
  deal: {
    descriptionTip: 'As alterações que você fez só serão salvas para este negócio.'
  },
  proposal: {
    descriptionTip: 'As alterações que você fez só serão salvas para esta proposta.'
  }
};

/* eslint-disable no-magic-numbers */
export const schema = Yup.array().of(
  Yup.object().shape({
    product_id: Yup.number().nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .required('Por favor, informe o produto.'),
    unit_value: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? 0 : value))
      .max(999999999.99, 'Preço unitário deve ser no máximo 999.999.999,99.'),
    quantity: Yup.number()
      .max(9999999, 'Quantidade deve ser no máximo 9.999.999.'),
    total_value: Yup.number()
  })
);
/* eslint-enable no-magic-numbers */

export const defaultValues = {
  product_id: '',
  unit_value: '0',
  quantity: '0',
  total_value: '0',
  discount: '0',
  subtotal: '0',
  discount_percentage: '0',
  description: ''
};

const calculateSubtotal = (unitValue, quantity) => Number(unitValue) * Number(quantity);

const calculateDiscount = (discountPercentage, subtotal) => (
  (Number(subtotal) * Number(discountPercentage)) / DISCOUNT_ROUNDED
);

const calculateTotalValue = (discountPercentage, subtotal) => (
  Math.round(
    (1 - (Number(discountPercentage) / 100)) * Number(subtotal)
  )
);

const formatDecimal = (number, scale = 2) => Number(number).toFixed(scale);

const parseCents = (string) => (
  string
    ? parseInt(string.replace('.', ''), 10)
    : 0
);
const formatCents = (number) => (
  number
    ? formatDecimal(number / 100)
    : '0.00'
);

const readableComponentClass = 'd-flex readable-component';

export function getInitialValues(items) {
  return items.map((item) => {
    const { id, product = {}, unitValue, quantity, discount, totalValue, metadataUrl } = item;
    const subtotal = calculateSubtotal(unitValue, quantity);
    const percentage = calculatePercentage(discount, subtotal);

    return {
      product_id: product?.id,
      name: product?.name,
      unit_value: formatDecimal(unitValue),
      quantity: quantity.toString(),
      discount: formatDecimal(discount, CENTS_SCALE),
      total_value: formatDecimal(totalValue),
      subtotal: formatDecimal(subtotal),
      discount_percentage: percentage.toString(),
      metadata_url: metadataUrl ?? product?.metadataUrl,
      ...(id ? { id } : {})
    };
  });
}

function ProductRow({ index, onRemove, size, hiddenFields, downloadMetadata, feature }) {
  const { getFieldState, setValue, formState, getValues } = useFormContext();

  const description = getValues(`entity_products.${index}.description`);
  const metadataUrl = getValues(`entity_products.${index}.metadata_url`);

  const [loadingDescription, setLoadingDescription] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(Boolean(description));
  const [descriptionTip, setDescriptionTip] = useState(false);
  // Description é pre-carregada pelo metadata da proposta, para evitar downloads desnecessários
  const [metadata, setMetadata] = useState(description ? { description } : null);
  const toggleDescription = () => setDescriptionExpanded((prev) => !prev);

  const isSmall = size === 'sm';
  const descriptionPlaceholder = metadata?.description
    ? ''
    : 'Descreva especificações ou coloque imagens do produto...';

  const fetchMetadataFrom = async (url) => {
    setLoadingDescription(true);
    try {
      const cacheTime = 5000;
      const metadataJSON = await downloadJSONFromUrl(url, {}, cacheTime);
      setMetadata(metadataJSON);
      const metadataDescription = metadataJSON?.description ?? '';
      setDescriptionExpanded(Boolean(metadataDescription));
      setLoadingDescription(false);
      setValue(`entity_products.${index}.description`, metadataDescription);
    } catch {
      setMetadata(null);
      setDescriptionExpanded(false);
      setLoadingDescription(false);
      setValue(`entity_products.${index}.description`, '');
    }
  };

  useEffect(() => {
    if (downloadMetadata && metadataUrl) {
      if (metadata === null && !description) {
        fetchMetadataFrom(metadataUrl);
      }
    }
  }, [metadataUrl, description, downloadMetadata, metadata]);

  const setSubtotal = (unitValue, quantity) => {
    const discountPercentage = getValues(
      `entity_products.${index}.discount_percentage`
    );

    const unitValueCents = parseCents(unitValue);

    const subtotalCents = calculateSubtotal(unitValueCents, quantity);
    const totalValueCents = calculateTotalValue(discountPercentage, subtotalCents);
    const calculatedDiscount = calculateDiscount(discountPercentage, subtotalCents);

    setValue(`entity_products.${index}.discount`, formatDecimal(calculatedDiscount, CENTS_SCALE));
    setValue(`entity_products.${index}.subtotal`, formatCents(subtotalCents));
    setValue(`entity_products.${index}.total_value`, formatCents(totalValueCents));

    setValue('sync_value', true);
  };

  const onChangeDiscountPercentage = (discountPercentage) => {
    const discount = getValues(`entity_products.${index}.discount`);
    const subtotal = getValues(`entity_products.${index}.subtotal`);

    const discountCents = parseCents(discount);
    const subtotalCents = parseCents(subtotal);

    const totalValueCents = calculateTotalValue(discountPercentage, subtotalCents);
    const calculatedDiscount = calculateDiscount(discountPercentage, subtotalCents);

    if (discountCents !== calculatedDiscount) {
      setValue(`entity_products.${index}.discount`, formatDecimal(calculatedDiscount, CENTS_SCALE));
      setValue(`entity_products.${index}.total_value`, formatCents(totalValueCents));

      setValue('sync_value', true);
    }
  };

  const onChangeUnitValue = (newUnitValue) => {
    const { error } = getFieldState(`entity_products.${index}.quantity`);
    const quantity = getValues(`entity_products.${index}.quantity`);

    if (!error) {
      setSubtotal(newUnitValue, quantity);
    }
  };

  const onChangeQuantity = (newQuantity) => {
    const { error } = getFieldState(`entity_products.${index}.unit_value`);
    const unitValue = getValues(`entity_products.${index}.unit_value`);

    if (!error) {
      setSubtotal(unitValue, newQuantity);
    }
  };

  const onChangeProduct = (_id, product) => {
    const productName = product?.option?.label || product?.name;
    setValue(`entity_products.${index}.name`, productName);
    if (product?.option?.price) {
      const newUnitValue = formatDecimal(product?.option?.price);

      setValue(`entity_products.${index}.unit_value`, newUnitValue);
      onChangeUnitValue(newUnitValue);
    }
    // Ao selecionar um produto, é feito download da description via metadataUrl
    const url = product?.option?.metadataUrl || product?.metadataUrl;
    if (url) {
      fetchMetadataFrom(url);
    }
  };

  const onChangeDescription = (changedDescription) => {
    setValue(`entity_products.${index}.description`, changedDescription);
    setDescriptionTip(true);
  };

  return (
    <>
      <tr>
        <td className='ps-0'>
          <HookFormField
            as={ProductSelect}
            name={`entity_products.${index}.product_id`}
            label=''
            placeholder='Buscar por nome, código ou categoria'
            className='mb-0'
            onChange={onChangeProduct}
            disabled={formState.isSubmitting}
            isCreatable
            defaultQueryParams={{ active_true: true }}
          />
        </td>

        <td>
          <HookFormField
            as={DecimalInput}
            name={`entity_products.${index}.unit_value`}
            placeholder='0,00'
            onChange={onChangeUnitValue}
            disabled={formState.isSubmitting}
            maskOptions={{
              clearMaskOnLostFocus: false,
              nullable: false,
              placeholder: '0'
            }}
            className={classnames(
              'mb-0',
              { 'opacity-50': hiddenFields?.productPrice }
            )}
          />
        </td>

        <td>
          <HookFormField
            as={IntegerInput}
            name={`entity_products.${index}.quantity`}
            placeholder='0'
            disabled={formState.isSubmitting}
            onChange={onChangeQuantity}
            className={classnames(
              'mb-0',
              { 'opacity-50': hiddenFields?.productQuantity }
            )}
          />
        </td>

        {!isSmall &&
          <td>
            <HookFormField
              as={DecimalInput}
              name={`entity_products.${index}.subtotal`}
              disabled={formState.isSubmitting}
              placeholder='R$ 0,00'
              readOnly
              className={classnames(
                'mb-0',
                { 'opacity-50': hiddenFields?.productTotal }
              )}
            />
          </td>
        }

        <td>
          <HookFormField
            as={PercentualInput}
            name={`entity_products.${index}.discount_percentage`}
            disabled={formState.isSubmitting}
            placeholder='0'
            onChange={onChangeDiscountPercentage}
            className={classnames(
              'mb-0',
              { 'opacity-50': hiddenFields?.productDiscount }
            )}
          />
        </td>

        <td>
          <HookFormField
            as={DecimalInput}
            name={`entity_products.${index}.total_value`}
            disabled={formState.isSubmitting}
            placeholder='0,00'
            className={classnames(
              'mb-0',
              { 'opacity-50': hiddenFields?.productTotalWithDiscount }
            )}
            readOnly
          />
        </td>

        <td className='pe-0'>
          <Tooltip
            content={descriptionExpanded ? 'Ocultar descrição' : 'Exibir descrição'}
          >
            <Button
              onClick={toggleDescription}
              disabled={loadingDescription}
              variant='outline-dark-gray'
              size='sm'
              className='p-2 lh-1'
            >
              {
                loadingDescription
                  ? <LoadSpinner size='sm' />
                  : (
                    <Icon name={descriptionExpanded ? 'arrow-up' : 'arrow-down'} size='sm' />
                  )
              }
            </Button>
          </Tooltip>
        </td>

        <td className='ps-3 pe-0'>
          <Button
            variant='outline-danger'
            size='sm'
            className='p-2 lh-1'
            disabled={formState.isSubmitting}
            onClick={onRemove}
            /*
             * Caso o clique dispare o `blur` de um input e isso gere
             * um erro de validação, o clique é cancelado.
             * ref: https://github.com/formium/formik/issues/1332
             */
            onMouseDown={(e) => e.preventDefault()}
          >
            <Icon name='delete' size='sm' />
          </Button>
        </td>
      </tr>

      <Collapse in={descriptionExpanded} dimension='width'>
        <tr className='mt-3'>
          <td colSpan='7' className='p-0'>
            <HookFormField
              as={RichTextInput}
              value={metadata?.description || ''}
              name={`entity_products.${index}.description`}
              onChange={onChangeDescription}
              disabled={formState.isSubmitting}
              className='my-1'
              minHeight={100}
              placeholder={descriptionPlaceholder}
            />

            { descriptionTip
              ? (
                <div className='d-flex align-items-middle text-small text-medium-gray mt-2'>
                  <Icon name='info' size='sm' className='me-1' />
                  {FEATURES[feature].descriptionTip}
                </div>
              )
              : null
            }
          </td>
        </tr>
      </Collapse>

      <td colSpan='12'>
        <hr className='mt-3' style={ { borderWidth: 2 } } />
      </td>
    </>
  );
}

function ProductHeader({
  isEmpty,
  size,
  hiddenFields,
  onChangeFieldVisibility,
  showToggleVisibility
}) {
  const isSmall = size === 'sm';

  if (isEmpty && !isSmall) {
    return null;
  }

  const labels = isSmall
    ? {
      item: 'Produtos e serviços',
      price: 'Preço',
      quantity: 'Quant.',
      subtotal: null,
      discount: 'Desc.',
      total_value: 'Total'
    }
    : {
      item: 'Item',
      price: 'Preço unitário (R$)',
      quantity: 'Quantidade',
      subtotal: 'Total (R$)',
      discount: 'Desconto (%)',
      total_value: 'Total com desconto (R$)'
    };

  if (!isEmpty) {
    return (
      <thead>
        <tr className='text-small text-nowrap'>
          <th
            className={classnames('ps-0', {
              'col-2': isSmall,
              'col-4': !isSmall
            })}
          >
            {labels.item}
          </th>

          <th
            className={classnames({
              'col-3': isSmall,
              'pe-4': !isSmall,
              'text-medium-gray': hiddenFields?.productPrice
            })}
          >
            <span className={readableComponentClass}>
              {labels.price}
              {
                showToggleVisibility
                  ? (
                    <ToggleFieldVisibility
                      hiddenFields={hiddenFields}
                      field={'productPrice'}
                      onChangeFieldVisibility={onChangeFieldVisibility}
                    />
                  )
                  : null
              }

            </span>
          </th>

          <th
            className={classnames({
              'col-2': isSmall,
              'pe-4': !isSmall,
              'text-medium-gray': hiddenFields?.productQuantity
            })}
          >
            <span className={readableComponentClass}>
              {labels.quantity}
              {
                showToggleVisibility
                  ? (
                    <ToggleFieldVisibility
                      hiddenFields={hiddenFields}
                      field={'productQuantity'}
                      onChangeFieldVisibility={onChangeFieldVisibility}
                    />
                  )
                  : null
              }
            </span>
          </th>

          {
            labels.subtotal &&
            <th className={classnames('col-2', {
              'text-medium-gray': hiddenFields?.productTotal
            })}
            >
              <span className={`${readableComponentClass} width-4`}>
                {labels.subtotal}
                {
                  showToggleVisibility
                    ? (
                      <ToggleFieldVisibility
                        hiddenFields={hiddenFields}
                        field={'productTotal'}
                        onChangeFieldVisibility={onChangeFieldVisibility}
                      />
                    )
                    : null
                }
              </span>
            </th>
          }

          <th
            className={classnames({
              'col-2': isSmall,
              'pe-7': !isSmall,
              'text-medium-gray': hiddenFields?.productDiscount
            })}
          >
            <span className={readableComponentClass}>
              {labels.discount}
              {
                showToggleVisibility
                  ? (
                    <ToggleFieldVisibility
                      hiddenFields={hiddenFields}
                      field={'productDiscount'}
                      onChangeFieldVisibility={onChangeFieldVisibility}
                    />
                  )
                  : null}
            </span>
          </th>

          <th
            className={classnames({
              'col-3': isSmall,
              'col-2': !isSmall,
              'text-medium-gray': hiddenFields?.productTotalWithDiscount
            })}
          >
            <span className={readableComponentClass}>
              {labels.total_value}
              {
                showToggleVisibility
                  ? (
                    <ToggleFieldVisibility
                      hiddenFields={hiddenFields}
                      field={'productTotalWithDiscount'}
                      onChangeFieldVisibility={onChangeFieldVisibility}
                    />
                  )
                  : null
              }
            </span>
          </th>

          <th className={classnames({ 'pe-0': !isSmall })}></th>
          <th className={classnames({ 'pe-0': !isSmall })}></th>
        </tr>
      </thead>
    );
  } else {
    return (
      <thead>
        <tr>
          <th className='text-small ps-0'>{labels.item}</th>
        </tr>
      </thead>
    );
  }
}

function ProductsSummary({ isEmpty, hiddenFields, onChangeFieldVisibility }) {
  const { setValue } = useFormContext();
  const entityProducts = useWatch({ name: 'entity_products' });
  const valueWithoutDiscount = sum(entityProducts?.map((item) => (
    item._destroy ? 0 : Number(item.subtotal)
  )));
  const valueWithDiscount = sum(entityProducts?.map((item) => (
    item._destroy ? 0 : Number(item.total_value)
  )));
  const discountValue = sum(entityProducts?.map((item) => (
    item._destroy ? 0 : Number(item.discount)
  )));

  const hasDiscount = valueWithoutDiscount !== valueWithDiscount;

  const discountPercentage = calculatePercentage(discountValue, valueWithoutDiscount);

  const productSummaryTotal = hiddenFields?.productSummaryTotal;
  const productSummaryDiscount = hiddenFields?.productSummaryDiscount;
  const productSummaryTotalWithDiscount = hiddenFields?.productSummaryTotalWithDiscount;

  useEffect(() => {
    setValue('calculated_value', valueWithDiscount.toString());
  }, [valueWithDiscount]);

  return (
    <div className='d-flex justify-content-end text-end'>
      <table className='table table-borderless w-50 table-sm'>
        <tbody>
          {
            (hasDiscount || (hiddenFields && !isEmpty)) &&
            <>
              <tr>
                <td className={classnames(
                  readableComponentClass,
                  'text-medium-gray fw-bold',
                  { 'text-medium-gray': productSummaryTotal }
                )}>
                  Valor
                  <ToggleFieldVisibility
                    hiddenFields={hiddenFields}
                    field={'productSummaryTotal'}
                    onChangeFieldVisibility={onChangeFieldVisibility}
                  />
                </td>
                <td className={productSummaryTotal ? 'opacity-50' : ''}>
                  {toCurrencyString(valueWithoutDiscount)}
                </td>
              </tr>
              <tr>
                <td className={classnames(
                  readableComponentClass,
                  'text-medium-gray fw-bold',
                  { 'text-medium-gray': productSummaryDiscount }
                )}>
                  Valor do desconto
                  <ToggleFieldVisibility
                    hiddenFields={hiddenFields}
                    field={'productSummaryDiscount'}
                    onChangeFieldVisibility={onChangeFieldVisibility}
                  />
                </td>
                <td className={productSummaryDiscount ? 'opacity-50' : ''}>
                  ({toPercentageString(discountPercentage)})
                  <span className='mx-1'>-</span>
                  {toCurrencyString(discountValue)}
                </td>
              </tr>
            </>
          }
          {
            isEmpty
              ? null
              : (
                <tr>
                  <td className={classnames(
                    readableComponentClass,
                    'text-dark-gray h4 mb-0',
                    { 'text-medium-gray': productSummaryTotalWithDiscount }
                  )}>
                    Valor total
                    <ToggleFieldVisibility
                      hiddenFields={hiddenFields}
                      field={'productSummaryTotalWithDiscount'}
                      onChangeFieldVisibility={onChangeFieldVisibility}
                    />
                  </td>
                  <td className={productSummaryTotalWithDiscount ? 'opacity-50' : ''}>
                    {toCurrencyString(valueWithDiscount)}
                  </td>
                </tr>
              )
          }
        </tbody>
      </table>
    </div>
  );
}

function HookProductsFields({
  size, hiddenFields, onChangeFieldVisibility, showToggleVisibility, downloadMetadata, feature
}) {
  const { fields, append, remove, update } = useFieldArray({
    name: 'entity_products',
    keyName: 'key'
  });
  const { setValue } = useFormContext();

  const nonRemovedProducts = fields.filter((item) => !item._destroy);

  const isEmpty = nonRemovedProducts.length === 0;
  const addButtonLabel = isEmpty ? 'Adicionar' : 'Adicionar outro';

  const handleRemove = (item, index) => {
    setValue('sync_value', true);

    if (item.id) {
      update(index, { ...item, _destroy: true });
    } else {
      remove(index);
    }
  };

  return (
    <Form.Group>
      <div>
        <Table borderless size='sm'>
          <ProductHeader
            showToggleVisibility={showToggleVisibility}
            isEmpty={isEmpty}
            size={size}
            hiddenFields={hiddenFields}
            onChangeFieldVisibility={onChangeFieldVisibility}
          />

          <tbody>
            {fields.map((item, index) => (
              !item._destroy &&
              <ProductRow
                className='opacity-50'
                key={`item_${item.key}`}
                index={index}
                onRemove={() => handleRemove(item, index)}
                downloadMetadata={downloadMetadata}
                size={size}
                hiddenFields={hiddenFields}
                feature={feature}
              />
            ))}
          </tbody>
        </Table>

        <Button
          variant='link'
          size='sm'
          className='px-0 fw-bold'
          onClick={() => append(defaultValues)}
        >
          <Icon name='plus' size='sm' className='me-1' />

          <span>{addButtonLabel}</span>
        </Button>
      </div>

      <ProductsSummary
        isEmpty={isEmpty}
        hiddenFields={hiddenFields}
        onChangeFieldVisibility={onChangeFieldVisibility}
        readableComponentClass={readableComponentClass}
      />
    </Form.Group>
  );
}

HookProductsFields.propTypes = propTypes;
HookProductsFields.defaultProps = defaultProps;

export default HookProductsFields;

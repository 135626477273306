import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import ProposalDocumentBody from '@/components/Proposal/ProposalDocumentBody';
import ProposalDocumentFooter from '@/components/Proposal/ProposalDocumentFooter';
import { getCsrfHeader } from '@/lib/fetch';
import { JSON_TYPE, blobToBase64, downloadBlobToBase64 } from '@/utils';
import logoImage from 'images/logo-proposal-footer.png';

ProposalPDF.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  filename: PropTypes.string,
  process: PropTypes.bool,
  onCreate: PropTypes.func
};

ProposalPDF.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  filename: '',
  process: false,
  onCreate: () => {}
};

const PDF_HEADERS = {
  'Content-Type': JSON_TYPE,
  ...getCsrfHeader()
};

/*
 * Responsavel por gerar HTML string dos componentes para enviar pro backend converter para PDF.
 * Atenção: wicked_pdf não suporta CSS moderno, partes do layout foi reescrito do jeito antigo.
 */
function ProposalPDF({ user, proposal, version, process, filename, onCreate }) {
  useEffect(() => {
    async function createPDF() {
      const banner = proposal?.banner;
      const logo = proposal?.logo;
      const bannerUrl = banner?.url ?? banner?.preview;
      const logoUrl = logo?.url ?? logo?.preview;

      const imagesBase64 = await Promise.all([
        logo?.blob ? blobToBase64(logo?.blob) : downloadBlobToBase64(logoUrl),
        banner?.blob ? blobToBase64(banner?.blob) : downloadBlobToBase64(bannerUrl),
        downloadBlobToBase64(logoImage)
      ]);

      const [logoBase64, bannerBase64, footerLogoBase64] = imagesBase64;
      const hideProposalFooterEnabled = user?.features?.hideProposalFooterEnabled;
      const content = `<!doctype html>${renderToString(
        <ProposalDocumentBody
          user={user}
          proposal={proposal}
          version={version}
          bannerBase64={bannerBase64}
          logoBase64={logoBase64}
        />
      )}`;

      const footer = hideProposalFooterEnabled
        ? '<div></div>'
        : `<!doctype html>${renderToString(
          <ProposalDocumentFooter
            user={user}
            proposal={proposal}
            logoBase64={footerLogoBase64}
          />
        )}`;

      const margin = { top: 4, bottom: 26, left: 0, right: 0 };
      const body = JSON.stringify({ content, footer, margin, filename });

      const response = await fetch('/api/pdf', {
        method: 'POST', body, headers: PDF_HEADERS, mode: 'cors'
      });

      const pdfBlob = await response.blob();
      const pdfData = { blob: pdfBlob, preview: URL.createObjectURL(pdfBlob) };

      onCreate(pdfData);
    }
    if (process) {
      createPDF();
    }
  }, [process]);

  return null;
}

export default ProposalPDF;

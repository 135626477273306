import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import { useCreateProduct, useProducts } from '@/api';
import { useDebounce } from '@/hooks';
import { canCreateProduct } from '@/policies';
import { useAuth } from '@/lib/auth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import APIErrorMessage from '@/components/APIErrorMessage';
import FetchableSelect from '@/components/Inputs/FetchableSelect';

const propTypes = {
  allowNull: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
};

const defaultProps = {
  allowNull: false,
  isClearable: true,
  isSearchable: true,
  label: 'Produtos',
  name: 'products_id_in',
  placeholder: 'Qualquer produto'
};

function transformer(fetchedData) {
  return fetchedData.data.map((product) => {
    const { name, code, category = {}, id, price, metadataUrl, active } = product;
    const valueWithPipe = (value) => (value ? `| ${value}` : '');
    const codeWithPipe = valueWithPipe(code);
    const categoryWithPipe = valueWithPipe(category?.name);
    const label = `${name} ${codeWithPipe} ${categoryWithPipe}`;

    return {
      label,
      price,
      metadataUrl,
      value: id,
      rightAdornment: !active && <span className='text-small text-medium-gray'>(inativo)</span>
    };
  });
}

const debounceTime = 600;

function ProductSelect(props) {
  const { allowNull, value, ...rest } = props;

  const { user } = useAuth();
  const [inputValue, setValue] = useState('');

  const alert = useAlert();
  const mutation = useCreateProduct();
  const handleInputChange = useDebounce(setValue, debounceTime);

  const onCreate = (name) => {
    /**
     * Após o clique na opção de adicionar, o campo de busca é limpo pelo
     * react-select. Isso é notificado pelo callback `onInputChange`, porém ele
     * está sendo tratado aqui com `debounce`.
     * Isso faz com que, caso a requisição seja mais rápida que o tempo de
     * debounce, após o sucesso, seja realizada uma requisição ainda
     * considerando a busca e, quando o tempo de debounce passa, ocorre outra
     * requisição, agora sem o termo de busca. Assim, para evitar essa primeira
     * requisição, aqui o valor da busca é limpo manualmente.
     */
    setValue('');

    /**
     * Como o react-select limpa o campo de busca após o clique na opção de
     * adicionar (vide comentário acima) e também não está sendo feito a
     * seleção imediata da nova opção (como no `ProductCategorySelect`),
     * enquanto está sendo feita a requisição de criação, aparecem as bolinhas
     * de loading, mas dentro do campo volta a aparecer o placeholder.
     * Para evitar isso, seria possível implementar a seleção imediata,
     * mas seria mais trabalhoso que no `ProductCategorySelect`, devido à
     * busca ser assíncrona.
     *
     */
    mutation.mutate({ name }, {
      onSuccess: ({ data }) => {
        props.onChange?.(data.id, data);

        alert.show(
          'Produto adicionado com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='product' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const { effect, reason } = canCreateProduct({ user });
  const isDisabled = effect !== 'allow';

  const defaultOptions = getDefaultOptions(allowNull);

  const queryArgs = useMemo(() => {
    const selectedIds = value ? [value].flat() : [];

    return {
      params: {
        q: inputValue,
        selected_ids: selectedIds
      }
    };
  }, [inputValue, value]);

  return (
    <FetchableSelect
      { ...rest }
      query={useProducts}
      transformer={transformer}
      cacheOptions
      onInputChange={handleInputChange}
      defaultOptions={defaultOptions}
      loadingMessage={loadingMessage}
      isLoading={mutation.isLoading}
      formatCreateLabel={(inputText) => (
        <Button
          variant='link'
          disabled={isDisabled}
          className='p-0 border-0 justify-content-start fw-bold'
        >
          <Icon name='plus' size='sm' className='me-2' />

          <span>
            Adicionar novo produto {`"${inputText}"`}
          </span>
        </Button>
      )}
      getNewOptionData={(inputText, label) => (
        {
          value: inputText, label, isDisabled, tooltip: reason
        }
      )}
      onCreateOption={onCreate}
      queryArgs={queryArgs}
      value={value}
    />
  );
}

function getDefaultOptions(allowNull) {
  return allowNull ? [{ label: 'Indefinido', value: -1 }] : [];
}

function loadingMessage() {
  return 'Carregando produtos...';
}

ProductSelect.propTypes = propTypes;
ProductSelect.defaultProps = defaultProps;

export default ProductSelect;
